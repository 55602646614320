<template>
  <div v-if="reportDetail">
    <NavTitle class="mb16" title="投诉详情"/>
    <div class="panel">
      <PanelTitle title="举报详情" :divided="false"/>
      <div class="content">
        <div class="title">被举报商家：{{merchantName}}</div>
        <div class="mt8" v-if="reportDetail.merchantContact">
          商家联系方式 
          <span>({{reportDetail.merchantContact.name}})</span>：
          <span>{{reportDetail.merchantContact.content}}</span>
        </div>
        <div class="title">举报用户：{{reportDetail.reporterName}}</div>
        <div class="mt8" v-if="reportDetail.reporterContact">
          用户联系方式
          <span v-if="reportDetail.reporterContact.name">({{reportDetail.reporterContact.name}})</span>：
          <span>{{reportDetail.reporterContact.content}}</span>
        </div>
        <div class="post-row">
          <div>
            举报岗位：
            <span class="theme-text">{{reportDetail.postName}}</span>
          </div>
          <div>举报类型：{{reportDetail.typeDesc}}</div>
        </div>
        <div class="img-list">
          <LoadImage class="img" :src="url" preview v-for="(url, index) in evidenceList" :key="index"></LoadImage>
        </div>
      </div>

      <PanelTitle class="mt24" title="沟通记录" :divided="false"/>
      <div class="content">
        <template v-if="messageRecord && messageRecord.length > 0">
          <div class="record" v-for="(item, index) in messageRecord" :key="index">
            <div class="head-row">
              <LoadImage class="avatar" :src="item.fromUidLogo" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
              <div>
                <div class="name">{{item.fromUserName}}</div>
                <div class="time">{{item.dt}}</div>
              </div>
            </div>
            <div class="desc">{{item.content}}</div>
            <div class="img-list">
              <LoadImage class="img" :src="url" preview v-for="(url, i) in item.pics" :key="i"></LoadImage>
            </div>
          </div>
        </template>
        <el-empty v-else description="暂无记录" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
        <!-- 底部按钮 -->
        <div class="flex justify-end items-center mt16" v-if="type == 1 || type == 2">
          <el-button type="danger" @click="handleAction('refuse')">{{type == 1?'投诉驳回':'撤销驳回'}}</el-button>
          <el-button class="ml16" type="primary" @click="handleAction('pass')">{{type == 1?'投诉通过':'撤销通过'}}</el-button>
          <el-button v-if="type == 1" class="ml16" type="primary" @click="$refs.replyPopupRef.open({rid})">留言回复</el-button>
        </div>
      </div>
    </div>

    <!-- 投诉成立/驳回 撤销成立/驳回弹窗 -->
    <Popup ref="popupRef" :title="`请输入${actionType == 'refuse' ? '驳回':'通过'}原因`" @confirm="handleRefuseOrPass">
      <el-input v-model="judgeReason" type="textarea" rows="3" maxlength="50" show-word-limit></el-input>
    </Popup>
    <!-- 留言回复 -->
    <ReplyPopup ref="replyPopupRef" @update="getReportDetail"></ReplyPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import Popup from '@/components/Popup'
import ReplyPopup from './components/ReplyPopup.vue'
import { reportDetailApi } from '@/api/report.js'
import { judgeReportApi, auditReportAppealApi } from '@/api/admin.js'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  name: 'ReportDetail',
  components: {
    NavTitle,
    PanelTitle,
    LoadImage,
    Popup,
    ReplyPopup
  },
  data() {
    return {
      rid: this.$route.query.rid,
      pid: this.$route.query.pid,
      merchantName: this.$route.query.merchantName,
      type: this.$route.query.type,
      reportDetail: null,
      evidenceList: [],
      messageRecord: [],
      actionType: '',
      judgeReason: ''
    }
  },
  created() {
    this.getReportDetail()
  },
  methods: {
    getReportDetail() {
      reportDetailApi({ 
        rid: this.rid,
        pid: this.pid
       }).then(res => {
        console.log('投诉详情', res)
        this.reportDetail = res.data
        const evidence = res.data.evidence || []
        const messages = res.data.messages || []
        this.evidenceList = []
        evidence.forEach(item => {
          if(item.startsWith('oss://')) {
            ossKeyToUrl({key: item}).then(ossRes => {
              this.evidenceList.push(ossRes.url)
            })
          }
        })
        this.messageRecord = messages.map(item => {
          if(item.fromUidLogo.startsWith('oss://')) {
            ossKeyToUrl({key: item.fromUidLogo}).then(ossRes => {
              item.fromUidLogo = ossRes.url
            })
          }
          if(item.pics.length > 0) {
            item.pics.forEach((pic, index) => {
              if(pic.startsWith('oss://')) {
                ossKeyToUrl({key: pic}).then(ossRes => {
                  this.$set(item.pics, index, ossRes.url)
                })
              }
            })
          }
          return item
        })
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handleAction(type) {
      this.actionType = type
      this.$refs.popupRef.open()
    },
    handleRefuseOrPass() {
      if(!this.judgeReason.trim()) return this.$tips({message: `请输入${this.actionType == 'refuse' ? '驳回':'成立'}原因`, type: 'warning' })
      if(!this.judgeReason.length > 50) return this.$tips({message: '原因不能超过50个字'})
      if(this.type == 1) {
        // 投诉成立/驳回
        judgeReportApi({
          'act': this.actionType == 'refuse'? 2 : 1,
          'judgeReason': this.judgeReason.trim(),
          'rid': this.rid
        }).then(res => {
          console.log('投诉成立/驳回',res)
          this.$tips({message: res.msg, type:'success'})
          this.$refs.popupRef.close()
          this.$router.go(-1)
        }).catch(err => {
          this.$tips({message: err, type:'error'})
        })
      } 
      if(this.type == 2) {
        // 撤销成立/驳回
        auditReportAppealApi({
          'act': this.actionType == 'refuse'? 2 : 1,
          'judgeReason': this.judgeReason.trim(),
          'rid': this.rid
        }).then(res => {
          console.log('撤销成立/驳回',res)
          this.$tips({message: res.msg, type:'success'})
          this.$refs.popupRef.close()
          this.$router.go(-1)
        }).catch(err => {
          this.$tips({message: err, type:'error'})
        })
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 24px;
  margin: 0 20px;
}
.content {
  padding: 0 54px;
  font-size: 14px;
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
  }
}
.post-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  font-size: 14px;
  color: #505E5C;
  margin-top: 8px;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  .img {
    width: 68px;
    height: 68px;
    border-radius: 4px;
    overflow: hidden;
    margin: 8px 20px 0 0;
  }
}
.record {
  margin-top: 16px;
  .head-row {
    display: flex;
    align-items: center;
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      margin-right: 8px;
    }
    .name {
      font-size: 16px;
      font-weight: bold;
    }
    .time {
      font-size: 12px;
      color: #505E5C;
      margin-top: 4px;
    }
  }
  .desc {
    font-size: 12px;
    color: #505E5C;
    margin-top: 8px;
  }
}

</style>